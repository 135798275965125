/*
 * File: _defaultTheme.scss                                                    *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Tue Oct 10 2023
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

@use "sass:color";
@use "@angular/material" as mat;
@use "variables";
@use "palette";
@use "fonts";

@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$teamshufflr-app-primary: mat.define-palette(palette.$md-teamshufflr);
$teamshufflr-app-accent: mat.define-palette(palette.$md-teamshufflr-secondary);

$teamshufflr-typography: mat.define-typography-config(
  $font-family: "Work Sans",
  $headline-3:
    mat.define-typography-level(56px, 56px, 700, $letter-spacing: -0.02em),
);

// The warn palette is optional (defaults to red).
$teamshufflr-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$teamshufflr-light-theme: mat.define-light-theme(
  (
    color: (
      primary: $teamshufflr-app-primary,
      accent: $teamshufflr-app-accent,
      warn: $teamshufflr-app-warn,
    ),
    typography: $teamshufflr-typography,
  )
);

$teamshufflr-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $teamshufflr-app-primary,
      accent: $teamshufflr-app-accent,
      warn: $teamshufflr-app-warn,
    ),
  )
);

@include mat.core-theme($teamshufflr-light-theme);
@include mat.all-component-themes($teamshufflr-light-theme);
@include mat.all-component-typographies($teamshufflr-typography);

.mat-mdc-form-field-focus-overlay,
.mat-mdc-text-field-wrapper {
  background-color: transparent !important;
  padding-left: 0 !important;
}

/**
* Fix for: https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone
*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
    background: transparent;
  }
}

.ts-primary {
  color: variables.$primaryColor;
}

.ts-accent {
  color: variables.$secondaryColor;
}

/* ================================= */
/* DEFINING PADDING CLASSES */

.ts-padding-s,
.ts-padding-m,
.ts-padding-l {
  box-sizing: border-box;
}

.ts-padding-s {
  padding: variables.$gapSmall;
}

.ts-padding-m {
  padding: variables.$gapMedium;
}

.ts-padding-l {
  padding: variables.$gapLarge;
}

/* ================================= */
/* DEFINING FLEX LAYOUT CLASSES */

.ts-flex-r {
  display: flex;
  flex-direction: row;
}

.ts-flex-c {
  display: flex;
  flex-direction: column;
}

.ts-flex-r,
.ts-flex-c {
  &.ts-wrap {
    flex-wrap: wrap;
  }
  &.ts-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  &.ts-align-start {
    justify-content: flex-start;
    align-items: flex-start;
  }
  &.ts-align-start-center {
    justify-content: flex-start;
    align-items: center;
  }
  &.ts-align-start-stretch {
    justify-content: flex-start;
    align-items: stretch;
  }
  &.ts-align-center {
    justify-content: center;
    align-items: center;
  }
  &.ts-align-stretch-start {
    justify-content: flex-start;
    align-items: stretch;
  }
  &.ts-align-stretch-center {
    justify-content: stretch;
    align-items: center;
  }
  &.ts-align-spaceBetween-center {
    justify-content: space-between;
    align-items: center;
  }
  &.ts-align-spaceBetween-stretch {
    justify-content: space-between;
    align-items: stretch;
  }
  &.ts-align-spaceBetween-start {
    justify-content: space-between;
    align-items: flex-start;
  }
  &.ts-align-spaceBetween-end {
    justify-content: space-between;
    align-items: flex-end;
  }
  &.ts-align-end-center {
    justify-content: flex-end;
    align-items: center;
  }

  &.ts-gap-xs {
    gap: variables.$gapExtraSmall;
  }
  &.ts-gap-s {
    gap: variables.$gapSmall;
  }
  &.ts-gap-m {
    gap: variables.$gapMedium;
  }
  &.ts-gap-l {
    gap: variables.$gapLarge;
  }
}

/* ============================ */
/* DEFINING ANIMATION KEYFRAMES */
@keyframes animateOutline {
  0% {
    outline: 0 solid transparent;
  }

  50% {
    outline: 5px solid variables.$primaryColor;
  }

  100% {
    outline: 0 solid transparent;
  }
}

/* ================================= */
/* DEFINING BASIC CLASSES */

.ts-rounded-card {
  border-radius: variables.$borderRadius !important;

  &.mat-button-base {
    .mat-button-wrapper {
      height: 100%;

      > .ts-flex-c {
        height: 100%;
      }
    }
  }
}

.ts-disable-selection {
  user-select: none;
}

.ts-content-may-blur {
  transition: filter 0.3s ease-in-out;
  will-change: filter;
}
.ts-blurred-content {
  filter: blur(variables.$defaultBlurRadius);
}

.ts-overflow {
  overflow: auto;
}

.ts-circle-dot {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  right: 0;
  transition: background-color 0.3s ease;
  border-radius: 100%;
  background-color: #f34949;
}

#navigationActions {
  transform: translate3d(0, 0, 0);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  flex-shrink: 0;
}

.mat-button-toggle-checked {
  color: #fff !important;
  background: variables.$primaryColor;
}

html,
body {
  height: 100%;
  min-height: --webkit-fill-available;
}

body {
  margin: 0;
  font-family: "Work Sans", "Helvetica Neue", sans-serif;
  background-color: variables.$lightGrey;
}

.mat-display-2 {
  font-weight: bold !important;
}

.mat-display-3,
.mat-display-2 {
  margin-bottom: variables.$gapLarge !important;
}

.mat-display-1 {
  margin-bottom: variables.$gapMedium !important;
}

p.ts-small-paragraph {
  margin-bottom: variables.$gapSmall !important;
}

.mat-bottom-sheet-container {
  border-top-left-radius: variables.$borderRadius !important;
  border-top-right-radius: variables.$borderRadius !important;
}

.mat-mdc-button-base:not(.mdc-icon-button, .mat-mdc-mini-fab, .mat-mdc-fab) {
  border-radius: variables.$borderRadius !important;
}

.mat-mdc-select-panel,
.lightSnackBar {
  border-radius: variables.$borderRadius !important;
}
.lightSnackBar {
  background-color: #fff;
  color: #000;

  .mat-simple-snackbar-action {
    color: variables.$primaryColor;
  }
}

@media only screen and (min-width: 751px) {
  #navigationActions {
    background-color: transparent !important;
  }
}

body.ts-dark-mode {
  @include mat.all-component-colors($teamshufflr-dark-theme);

  $bodyBackground: map-get($teamshufflr-dark-theme, "background", "background");
  $color: map-get($teamshufflr-dark-theme, "foreground", "text");
  background-color: $bodyBackground;
  color: $color;

  .ts-light-background {
    $background: map-get($teamshufflr-dark-theme, "background", "card");
    background: color.scale($background, $lightness: -10%);

    &.ts-rounded-card {
      border: 1px solid color.scale($background, $lightness: -10%);
    }
  }

  .ts-light-background-1 {
    $background: map-get($teamshufflr-dark-theme, "background", "card");
    background: color.scale($background, $lightness: 0%);
    &.ts-rounded-card {
      border: 1px solid color.scale($background, $lightness: 0%);
    }
  }
}

.ts-primary-background {
  $background: map-get($teamshufflr-app-primary, "default");
  background: $background;
  &.ts-rounded-card {
    border: 1px solid $background;
  }
}

.ts-light-background-1 {
  $background: map-get($teamshufflr-light-theme, "background", "background");
  background: color.scale($background, $lightness: 0%);
  &.ts-rounded-card {
    border: 1px solid color.scale($background, $lightness: 0%);
  }
}

.ts-light-background {
  $background: map-get($teamshufflr-light-theme, "background", "card");
  background: $background;
  &.ts-rounded-card {
    border: 1px solid $background;
  }
}
