/*
 * File: styles.scss                                                           *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Sat Jan 22 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

/* ======================*/
/* Forward layout themes */
@forward "../../layouts/canvas-page-layout/src/canvas-page-layout-theme";

/* ======================== */
/* Forward component themes */
@forward "../../components/session-chat/src/session-chat-theme";

/* ===================== */
/* Forward dialog themes */
@forward "../../dialogs/dialog/src/dialog-theme";

/* ========================= */
/* Forward the default theme */
@forward "./defaultTheme";

/* ================================= */
/* Forward external themes */
@forward "../../../../node_modules/ngx-sharebuttons/themes/default";
