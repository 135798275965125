/*
 * File: _canvas-page-layout-theme.scss                                        *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Fri Jan 07 2022
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

@use "variables";

ts-canvas-page {
  .ts-canvas-page-title {
    margin: 0 0 variables.$gapMedium 0 !important;
  }
}

.ts-canvas-page-center-content-scroll-container {
  &,
  .ts-canvas-page-center-content {
    min-height: 100%;
  }

  .ts-canvas-page-center-content {
    flex: 1;
  }
}

@media only screen and (max-width: 750px) {
  ts-canvas-page {
    grid-template-rows: auto 1fr !important;
    grid-template-columns: 100% !important;
    grid-template-areas:
      "left-side-bar"
      "center-content" !important;
    min-height: 100% !important;
    overflow: auto !important;
    height: unset !important;
    padding: variables.$gapLarge variables.$gapMedium 0 variables.$gapMedium !important;

    ts-canvas-floating-left-bar {
      display: none !important;
    }

    ts-canvas-floating-right-bar {
      position: relative;
      top: 0;
      right: 0;
      margin-top: 0;
      flex-direction: row;
      justify-content: space-between;
      padding: variables.$gapMedium 0;
      border-top: 1px solid;
      margin-bottom: variables.$gapMedium;
    }
    .ts-canvas-page-center-content-scroll-container {
      overflow: unset !important;

      .ts-canvas-page-center-content {
        padding-bottom: #{variables.$gapLarge + variables.$navbarHeight};
      }
    }

    .ts-canvas-floating-left-bar-header-container {
      img {
        display: none !important;
      }
    }
  }
}
