/*
 * File: _palette.scss                                                         *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Tue Oct 10 2023
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

$md-teamshufflr: (
  50: #e1f2f5,
  100: #b5dfe6,
  200: #83c9d5,
  300: #51b3c4,
  400: #2ca3b7,
  500: #0793aa,
  600: #068ba3,
  700: #058099,
  800: #047690,
  900: #02647f,
  A100: #adeaff,
  A200: #7addff,
  A400: #47d0ff,
  A700: #2dc9ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-teamshufflr-secondary: (
  50: #f8f8eb,
  100: #edefce,
  200: #e1e4ad,
  300: #d5d98c,
  400: #ccd073,
  500: #c3c85a,
  600: #bdc252,
  700: #b5bb48,
  800: #aeb43f,
  900: #a1a72e,
  A100: #feffeb,
  A200: #fbffb8,
  A400: #f8ff85,
  A700: #f7ff6c,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
