/*
 * File: _variables.scss                                                       *
 * Author: mafo (maximilian.fossler@teamshufflr.com)"                          *
 * Last Modified: Wed Dec 29 2021
 * -----                                                                       *
 * Copyright (C) 2021, teamshufflr                                             *
 * All rights reserved.                                                        *
 * -----                                                                       *
 * Unauthorized copying of this file, via any medium is strictly prohibited    *
 * Proprietary and confidential                                                *
 */

// TEAMSHUFFLR-Values
$primaryColor: #0793aa;
$secondaryColor: #c3c85a;
$lightGrey: #f8f8f8;
$darkGrey: #212121;
$gapLarge: 30px;
$gapMedium: 15px;
$gapSmall: 10px;
$gapExtraSmall: 5px;

$navbarHeight: 70px;
$borderRadius: 15px;

$contentMaxWidth: 1200px;
$stepperMaxWidth: 650px;

$defaultBlurRadius: 3px;
